/*-------------------- START - TIPOGRAPHY STYLES --------------------*/
.blue-text {
  color: #002EFF !important;
}
.orange-text {
  color: #FF3D00 !important;
}
.green-text {
  color: #73af45 !important;
}
.regular-text {
  font-weight: 300 !important;
}
.text-grey {
  color: #686868 !important;
}
.text-center {
  text-align: center;
}
.text-red {
  color: #d82e2e !important;
}
/*-------------------- END - TIPOGRAPHY STYLES --------------------*/
/*------------------------- START - BTN -------------------------*/
.btn-report-automator {
  background-color: #ffffff !important;
  border: 1.5px solid #e6e9ed !important;
  color: #31669F !important;
  background-size: 200% auto;
  border-radius: 7px !important;
  font-size: 14px !important;
  text-transform: none !important;
  padding: 3px 12px !important;
}
.btn-report-automator:hover {
  background-color: #f5fbff !important;
  color: #2B4986 !important;
}
.btn-principal {
  background-color: #002EFF !important;
  border: 1.5px solid #e6e9ed !important;
  color: #ffffff !important;
  background-size: 200% auto;
  border-radius: 25px !important;
  font-size: 14px !important;
  text-transform: none !important;
  padding: 3px 12px !important;
  height: 40px !important;
}
.btn-principal:hover {
  background-color: #ffffff !important;
  color: #002EFF !important;
  border: 1.5px solid #002EFF !important;
}
.btn-secondary {
  background-color: #ffffff !important;
  border: 1.5px solid #e6e9ed !important;
  color: #5e5e5e !important;
  background-size: 200% auto;
  border-radius: 7px !important;
  font-size: 14px !important;
  text-transform: none !important;
  padding: 3px 12px !important;
}
.btn-secondary:hover {
  background-color: #f7f7f7 !important;
  color: #383838 !important;
}
.btn-red {
  background-color: #ffffff !important;
  border: 1.5px solid #e6e9ed !important;
  color: #d82e2e !important;
  background-size: 200% auto;
  border-radius: 7px !important;
  font-size: 14px !important;
  text-transform: none !important;
  padding: 3px 12px !important;
}
.btn-red:hover {
  background-color: #fff5f5 !important;
  color: #ad1010 !important;
}
.nav-link:hover{
  color: #1a61ee !important;
}
.btn-navbar {
  background-color: #ffffff00 !important;
}
.btn-navbar:hover {
  background-color: #ffffff00 !important;
}
.exit-btn{
  border: none !important;
  color: #afafaf !important;
  width: auto !important;
  min-width: none !important;
  display: flex !important;
  justify-content: end !important;
}
.exit-btn:hover{
  color: #7f7f7f !important;
  background-color: transparent !important;
}
.btn-principal-borderless {
  background-color: none!important;
  border: none !important;
  color: #31669F !important;
  font-size: 13px !important;
  padding: 3px !important;
  min-width: 20px !important;
}
.btn-principal-borderless:hover {
  color: #2B4986 !important;
  background-color: transparent !important;
}
/******+******************************
         BOX BUTTONS
**************************************/
.btn-input {
  display: none;
}
.padding-modal {
  padding-bottom: 2vh !important;
}
/*------------------------- END - BTN -------------------------*/
/*------------------------- START - GENERAL -------------------------*/
body {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  box-sizing: border-box !important;
  overflow-x: hidden !important;
}
header {
  margin: 0 !important;
  padding: 0 !important;
}
html {
  font-size: 90% !important;
}
.margin-general {
  margin-top: 15px;
  margin-bottom: 15px;
}
.red-box {
  border-color: #d82e2e !important;
  border-radius: 5px !important;
}
.center-elements {
  justify-content: center !important;
  display: flex !important;
  text-align: center !important;
}
.css-ao8rxk {
  box-shadow: none !important;
}
.navbar {
  background-color: #FFFFFF !important;
}
.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}
.overflow-hidden {
  overflow-wrap: break-word;  /* Asegúrate de que el texto no desborde verticalmente */  
  text-overflow: ellipsis;
  max-width: 100%; /* O establece el ancho máximo que desees */
}

/******+******************************
            KIWOX STYLES
**************************************/
.kiwox-panel {
  border: 1px solid #e6e9ed;
  margin: 0px 0px 10px 0px;
  background-color: white;
  margin-top: 3rem !important;
}
.kiwox-panel-title {
  border-bottom: 1px solid #e6e9ed;
  font-weight: 400;
  padding: 15px 15px 15px 15px;
  margin: 0px 0px 5px 0px;
  background-color: #f8f9fc;
  letter-spacing: 0.7px;
  color: #002EFF;
}
.kiwox-panel-body {
  padding: 10px 10px;
  font-weight: 300;
}
.kiwox-panel-table {
  width: 100%;
  font-weight: 300;
}
/*------------------------- END - GENERAL -------------------------*/
/*------------------------- START - LOGIN -------------------------*/
/******+******************************
            LOGIN CONTAINER
**************************************/
.login-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 100vh !important;
}
.bg-login {
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    );
  background-position: center;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
}
/******+******************************
          LOGIN BOX
**************************************/
.container-box {
  border: none !important;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  padding-left: 3vh !important;
  padding-right: 3vh !important;
  padding-top: 3vh !important;
  padding-bottom: 3vh !important;
}
.container-box-login {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding-top: 3vh !important;
  padding-bottom: 3vh !important;
  padding-left: 2vh !important;
  padding-right: 2vh !important;
}
/******+******************************
          LOGIN BOX FORM
**************************************/
.box-form {
  max-width: 400px !important;
  width: 100% !important;
  display: contents;
}
/******+******************************
          LOGIN USR/PWD
**************************************/
.form-username,
.form-password {
  margin-bottom: 16px !important;
  width: 100% !important;
}
/******+******************************
        LOGIN IMG
**************************************/
.img-margin {
  margin-bottom: 20px;
}
/******+******************************
        LINK RECOVERY PASSWORD
**************************************/
.recovery-password {
  color: #007cc1 !important;
  text-decoration: none !important;
}
.recovery-password:visited {
  color: #007cc1 !important;
}
.recovery-password:hover {
  color: #69b6ea !important;
}
.recovery-password:active {
  color: #69b6ea !important;
}
/*------------------------- END - LOGIN -------------------------*/
/*------------------------- START - MODAL -------------------------*/
/******+******************************
             BOX LOGIN
**************************************/
.custom-modal{
  border: none !important;
}
.box-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 15px;
  max-height:97vh !important;
  overflow-y: auto !important;
}
.box-modal-container{
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
}
.box-modal-xs {
  padding-bottom: 35px !important;
}
@media screen and (min-width: 1201px) {
  .box-modal-xs {
  max-width: 25vw !important;
  width: 25vw !important;
  min-width: 25vw !important;
}
.box-modal-sm {
  max-width: 30vw !important;
  width: 30vw !important;
  min-width: 30vw !important;
}
.box-modal-md {
  max-width: 30vw !important;
  width: 30vw !important;
    min-width: 30vw !important;
}
.box-modal-lg {
  max-width: 30vw !important;
  width: 30vw !important;
  min-width: 30vw !important;
}
}
@media screen and (max-width: 1200px) {
  .box-modal-sm {
    max-width: 40vw !important;
    width: 40vw !important;
    min-width: 40vw !important;
  }
  .box-modal-md {
    max-width: 50vw !important;
    width: 50vw !important;
    min-width: 50vw !important;
  }
  .box-modal-lg {
    max-width: 50vw !important;
    width: 50vw !important;
    min-width: 50vw !important;
  }
  }
  @media screen and (max-width: 1000px){
    .box-modal-xs {
      max-width: 40vw !important;
      width: 40vw !important;
      min-width: 40vw !important;
    }
  }
@media screen and (max-width: 900px) {
  .box-modal-sm {
    max-width: 50vw !important;
    width: 50vw !important;
    min-width: 50vw !important;
  }
  .box-modal-md {
    max-width: 60vw !important;
    width: 60vw !important;
    min-width: 60vw !important;
  }
  .box-modal-lg {
    max-width: 70vw !important;
    width: 70vw !important;
    min-width: 70vw !important;
  }
}
@media screen and (max-width: 700px){
  .box-modal-xs {
    max-width: 50vw !important;
    width: 50vw !important;
    min-width: 50vw !important;
  }
  .box-modal-lg {
    max-width: 70vw !important;
    width: 70vw !important;
    min-width: 70vw !important;
  }
}
@media screen and (max-width: 500px){
  .box-modal-lg {
    max-width: 90vw !important;
    width: 90vw !important;
    min-width: 90vw !important;
  }
}
/*------------------------- END - ERROR MODAL -------------------------*/
/*------------------------- START - NAVBAR -------------------------*/
/******+******************************
             MENU 
**************************************/
.navbar-img {
  margin-right: 30px !important;
}
.btn-user {
  margin-left: auto !important;
}
.navbar-text {
  margin-left: 3px !important;
}
/*------------------------- END - NAVBAR -------------------------*/
/*------------------------- START - GETINVENTORY -------------------------*/
.css-11mde6h-MuiPaper-root {
  box-shadow: none !important;
}
html,
body,
#root,
#root > div {
  height: 100%;
}
/*------------------------- END - GETINVENTORY -------------------------*/
/*------------------------- START - CREATE SKU MODAL -------------------------*/
.custom-formControl {
  width: 100% !important;
  margin-top: 2px !important;
  overflow-x: auto; /* Evita desbordamiento horizontal */
  overflow-wrap: break-word;  /* Asegúrate de que el texto no desborde verticalmente */  
}
.tableCell {
  height: 80px !important;
}
.table-create .css-1ex1afd-MuiTableCell-root {
  padding: 7px;
}
.table-create .tableRow {
  height: 10px !important;
}
.table-create .tableRow {
  font-size: "14px" !important;
  height: 2px !important;
}
.create-form {
  min-width: 70vh !important;
}
/*------------------------- END - CREATE SKU MODAL -------------------------*/
/*------------------------- START - ICON'S COLORS -------------------------*/
.icon-eliminar {
  color: #d82e2e;
}
.icon-eliminar:hover {
  color: #990000;
}
.icon-grave {
  color: #f32000;
}
.icon-precaucion {
  color: #ffb600;
}
.icon-ok {
  color: #77aa2c;
}
.icon-regular {
  color: #3992C5;
}
.icon-regular:hover {
  color: #3375AC;
}
/*------------------------- END- ICON'S COLORS -------------------------*/
/******+******************************
        INVENTORY TABLE ICONS
**************************************/
.table-actions-container {
  text-align: left !important;
}
.inventoryTable-icons-container {
  display: flex !important;
}
.inventoryTable-icon-view {
  margin-right: 10px !important;
  cursor: pointer !important;
}
.inventoryTable-icon-open {
  margin-right: 10px !important;
  cursor: pointer !important;
}
.inventoryTable-icon-comment {
  margin-right: 10px !important;
  cursor: pointer !important;
}
.inventoryTable-icon-edit {
  margin-right: 10px !important;
  cursor: pointer !important;
}
/******+******************************
          CSS COLUMNA ICONOS
**************************************/
/* Estilos para los contenedores de íconos en las filas */
.inventoryTable-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Estilos para los contenedores individuales de cada ícono */
.inventoryTable-icons-container div {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*------------------------- START INPUT LINE -------------------------*/

/*------------------------- END INPUT LINE -------------------------*/
/******+******************************
    INVENTORY BOX HEADER
**************************************/
.getInventory-box-header {
  margin-top: 2% !important;
  margin-bottom: 1% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.container-title {
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 16px 4px rgba(117, 117, 117, 0.14);
  -moz-box-shadow: 0px 0px 16px 4px rgba(117, 117, 117, 0.14);
  box-shadow: 0px 0px 16px 4px rgba(117, 117, 117, 0.14);
}
/******+******************************
    INVENTORY SEARCH TABLE
**************************************/
/* Estilo para alinear buscador y paginación en la misma fila */
.searchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Agregar un salto de línea si el contenido no cabe en el ancho disponible */
}
.searchContainer2 {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-end; /* Alinea la paginación a la derecha del contenedor */
}

.tablePagination p {
  text-align: right;
  margin: 0;
}
/******+******************************
       CREATE SKU MODAL BOX
**************************************/
.form-g {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.24);
  padding: 16px;
  width: 90%;
  height: 70%;
  overflow-y: auto;
}
/******+******************************
        CREATE SKU MODAL FORMCONTROL SPACER
**************************************/
.createSkuModal-modal-box-formControl-spacer {
  margin-top: 10px !important;
}
/******+******************************
       CREATE SKU MODAL 
**************************************/
.createTicket-box {
  max-width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
/******+******************************
       CREATE SKU MODAL
**************************************/
.createTicket-form {
  width: 100% !important;
  margin-top: 3% !important;
}
.form-spacer {
  margin-top: 10px !important;
}
.createTicket-menu {
  max-height: 200px !important;
  width: 250px !important;
}
.createTicket-paper {
  padding: 16px !important;
  margin-top: 1% !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
}
.createTicket-list {
  margin-top: 24px !important;
}
/******+******************************
       CREATE SKU MODAL
**************************************/
.createTicket-commentHistory-typography {
  font-weight: bold !important;
}
.createTicket-comment-box {
  margin-top: 3% !important;
}
.createTicket-comment {
  width: 100% !important;
  height: 200px !important ;
  font-size: 16px !important;
}

/******+******************************
          NOT FOUND CONTAINER
**************************************/
.notFound-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f8f8;
}
/******+******************************
            NOT FOUND IMAGE
  **************************************/
.notFound-container img {
  width: 200px;
  margin-bottom: 20px;
}
/******+******************************
            NOT FOUND MESSAGES
  **************************************/
.notFound-container h2 {
  font-size: 24px;
  color: #333;
}
.notFound-container p {
  font-size: 18px;
  color: #555;
}
/******+******************************
            NOT FOUND URL
  **************************************/
.notFound-container a {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.notFound-container a:hover {
  background-color: #0056b3;
}
/******+******************************
            SET LOADING MODAL
  **************************************/
.loading-modal-container {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  text-align:center;
  transform: translate(-50%, -50%) !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100vh !important;
  width: 100% !important;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999 !important;
}

.loading-container {
  width: 10vw;
  height: auto;
  text-align: center;
  background-color: rgba(92, 92, 92, 0.818);
  padding: 2%;
  border-radius: 25%;
}
.loading-image-static {
  width: 100%;
  height: auto;
}
.loading-image-animated {
  animation: anim 1.5s linear 0s infinite alternate-reverse none;
  width: 100%;
  height: auto;
}

@keyframes anim {
  0% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100%;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}
/*------------------------- START - TABLA GESTIÓN DE VENTAS -------------------------*/
/******+******************************
         ANCHO DE COLUMNAS
**************************************/
.special-width-big {
  min-width: 20ch !important;
  width: 20ch !important;
  max-width: 20ch !important;
}
.special-width-medium {
  min-width: 15ch !important;
  width: 15ch !important;
  max-width: 15ch !important;
}
.special-width-small {
  min-width: 10ch !important;
  width: 10ch !important;
  max-width: 10ch !important;
}
/******+******************************
         BÚSQUEDA POR RUT
**************************************/
.rut-filter {
  width: 100% !important;
}
/*------------------------- END - TABLA GESTIÓN DE VENTAS -------------------------*/
/*------------------------- START - AUTOSUGGEST -------------------------*/
body {
  font-family: Helvetica, sans-serif;
}
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.react-autosuggest__input--focused {
  outline: none;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
/*------------------------- END - AUTOSUGGEST -------------------------*/
/*------------------------- START - PRINT -------------------------*/
.print-show-delete{
  visibility: hidden;
  display: none;
}
@media print {
  @page { 
    size: auto;
  }

  body * {
    visibility: visible;
  }
  #print,
  #print * {
    visibility: visible;
    overflow: visible !important;
  }
  #print {
    position: absolute;
    left: 5%; /* Centra horizontalmente en el 50% del ancho de la página */
    right: 5%;
    top: 5%; /* Centra verticalmente en el 50% del alto de la página */
    bottom: 5%;
    overflow: visible !important;
    max-height: none !important; /* Eliminado el maxHeight */
  }
  #print .print-folio{
    text-align: end !important;
  }
  #print .print-show-delete{
    visibility: visible !important;
    display: block !important;
  }
  table {
    page-break-inside: auto; /* Permite que las tablas se dividan entre páginas si es necesario */
  }

  .footer-print {
    page-break-before: always; /* Agregado para forzar el footer al final de cada página */
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
/*------------------------- END - PRINT -------------------------*/
/*------------------------- START - INPUT LINE -------------------------*/
.css-q0jhri-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}
/*------------------------- END - INPUT LINE -------------------------*/
/*------------------------- START - INPUT LINE -------------------------*/
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  box-sizing: border-box !important;
}
/*------------------------- END - INPUT LINE -------------------------*/
/*------------------------- START - TEXTOS DESTACADOS -------------------------*/
.destacado-fondo {
  background-color: #42E8B4;
  border: 1px solid #42E8B4;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 2px;
}
.destacado-borde{
  border: 1px solid #42E8B4;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 2px;
}
.destacado-fondo-completo {
  background-color: #42E8B4;
  border: 1px solid #42E8B4;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 2px;
}
/*------------------------- END - INPUT LINE -------------------------*/
/*------------------------- START - MODIFICACIÓN BOOTSTRAP CARD -------------------------*/
.card{
  border-radius: 8px !important;
}
/*------------------------- END - MODIFICACIÓN BOOTSTRAP CARD -------------------------*/
/*------------------------- START - INPUT LINE -------------------------*/
.tabla-reporte tbody td{
  border: 1px solid #002EFF !important;
}
.tabla-reporte thead th{
  background-color: #002EFF !important;
  color: #ffffff;
  border: 1px solid #7d9af2;
}
/*------------------------- END - INPUT LINE -------------------------*/
/*------------------------- START - ICONOS DE VALIDACION INPUTS FORMULARIO -------------------------*/
.green-check {
  color: #5CD100;
}
.red-cross{
  color: #FF2929;
}
/*------------------------- END - ICONOS DE VALIDACION INPUTS FORMULARIO -------------------------*/
/*------------------------- START - INPUT BOX LINE -------------------------*/
.css-1x5jdmq {
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: border-box !important; 
  background: none;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}
/*------------------------- END - INPUT BOX LINE -------------------------*/

/*Fix duplicado de tabla EXPERIMENTAL */
.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon:nth-last-of-type(2) {
  border-bottom: none;
  display: none;
}
/*------------------------- END - INPUT BOX LINE -------------------------*/
/*------------------------- START - BTN -------------------------*/
.btn-principal {
  background-color: #002EFF !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  height: 35px !important;
  padding: 3px 12px !important;
}
.btn-principal:hover {
  background-color: #ffffff !important;
  color: #002EFF !important;
  border: 1.5px solid #002EFF !important;
}
/*------------------------- END - BTN -------------------------*/
/*------------------------- START - NAVBAR -------------------------*/
.login-title{
  color: #002EFF !important;
}
/*------------------------- END - NAVBAR -------------------------*/
/*------------------------- START - BTN FACTIBILIDAD -------------------------*/
.btn-factibilidad{
  border: solid 1px #71eec7 !important;
  color: #002EFF !important;
  background-color: #ffffff !important;
  
}
/*------------------------- END - BTN FACTIBILIDAD -------------------------*/

/*------------------------- START - CONTENEDOR DE FILTROS MISVENTAS -------------------------*/
.container-filter{
  height: 75px;
}
/*------------------------- END - CONTENEDOR DE FILTROS MISVENTAS -------------------------*/

.navbar-toggler-icon {
  background-image: url("https://lafibradetodos.cl/static/img/icon-collapse-button.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 24px; 
  vertical-align: middle;
  width: 24px; 
}


.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 5px 0 5px 10px!important; 
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  /* padding: 16.5px 14px; */
}

.css-qiwgdb {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 5px 0 5px 10px!important; 
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  /* padding: 16.5px 14px; */
}
/*------------------------- terminos y condiciones -------------------------*/
.scroll-container {
  height: 300px;
    overflow-y: auto;
    background-color: #fafafa;
    color: #a2a2a2;
    border-radius: 4px;
    border: solid 1px #E7E7E7;
    font-size: 80%;
}
/*------------------------- START PLANS -------------------------*/
.card-plan{
  min-height: px;
  height: px;
  max-height: px;
}
@media screen and (max-width: 750px){
  .card-plan{
    min-height: px;
    height: px;
    max-height: px;
  }
}
@media screen and (max-width: 750px){
  .card-plan{
    min-height: px;
    height: px;
    max-height: px;
  }
}
@media screen and (max-width: 750px){
  .card-plan{
    min-height: px;
    height: px;
    max-height: px;
  }
}
@media screen and (max-width: 750px){
  .card-plan{
    min-height: px;
    height: px;
    max-height: px;
  }
}
@media screen and (max-width: 750px){
  .card-plan{
    min-height: px;
    height: px;
    max-height: px;
  }
}
@media screen and (max-width: 750px){
  .card-plan{
    min-height: px;
    height: px;
    max-height: px;
  }
}
/*------------------------- END PLANS -------------------------*/

.box-modal-smartCampaign {
  max-width: 65vw !important;
  width: 65vw !important;
  min-width: 65vw !important;
}
@media screen and (min-width: 0px) and (max-width: 865px){
  .box-modal-smartCampaign {
    max-width: 75vw !important;
    width: 75vw !important;
    min-width: 90vw !important;
  }
}
@media screen and (min-width: 1200px) {
  .box-modal-smartCampaign {
    max-width: 40vw !important;
    width: 40vw !important;
    min-width: 40vw !important;
  }
}
.imei-smart {
  width: 60%;
}
.tabla-interna .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
box-shadow: none !important;
}